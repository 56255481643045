import React from 'react';
import ImagesProvider from '@/contexts/images';
import { Seo } from '@/components';
import { Root } from '@/layouts';
import Hero from '@/layouts/Funcionalidades/Hero';
import { ConhecaMais, ConhecaPlanos, Detail } from '@/layouts/Funcionalidades';
import FUNCIONALIDADES from '@/constants/funcionalidades';
import { ProntuarioDigitalDetails } from '@/constants/pageDetails';

import atendimento from '@/assets/images/tela-do-prontuario-digital-do-simples-dental.svg';
import { buttonEvents } from '@/constants/analytics';

const schema = {
  '@context': 'https://schema.org/',
  '@type': 'BreadcrumbList',
  itemListElement: [
    {
      '@type': 'ListItem',
      position: 1,
      name: 'Home',
      item: 'https://www.simplesdental.com/',
    },
    {
      '@type': 'ListItem',
      position: 2,
      name: 'Prontuário Digital',
    },
  ],
};

const ProntuarioDigital = () => {
  const name = FUNCIONALIDADES.atendimentoClinico.name;

  return (
    <ImagesProvider>
      <Seo
        title="Todas as informações dos pacientes organizadas"
        description="Acesse de forma simples, rápida e totalmente segura a ficha online de cada paciente. O Software mais moderno que sua clínica precisa."
        schema={schema}
      />
      <Root
        variant="light"
        hero={
          <Hero
            title={name}
            desc="Todas as informações dos pacientes em um único lugar"
            img={atendimento}
            alt={name}
            imageTitle="Tela do prontuário digital do Simples Dental"
            gaMetadata={{ buttonEvent: buttonEvents.prontuario }}
            width={690}
            height={405}
          />
        }
      >
        {ProntuarioDigitalDetails.map((i, index) => (
          <Detail item={i} index={index} key={index} />
        ))}

        <ConhecaPlanos gaMetadata={{ buttonEvent: buttonEvents.prontuario }} />

        <ConhecaMais page={name} gaMetadata={{ saibaMaisButtonEvent: buttonEvents.prontuario }} />
      </Root>
    </ImagesProvider>
  );
};

export default ProntuarioDigital;
